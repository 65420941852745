import * as React from "react"

const BGPattern = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 560" preserveAspectRatio="xMinYMin slice">
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      
    </g>
  </svg>
)

export default BGPattern